<template>
  <div class="contain">
    <div class="left-part">
      <h3>
        {{ titleStatus == 1 || titleStatus == 2 ? "基础配置" : "安检信息" }}
      </h3>
      <el-form
        v-if="titleStatus == 1 || titleStatus == 2"
        ref="form"
        label-position="top"
        :model="form"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="安检计划" prop="periodId">
          <el-select
            v-model="form.periodId"
            placeholder="请选择"
            filterable
            size="small"
            style="width: 100%"
            @change="cleardata()"
          >
            <el-option
              v-for="item in periodLists"
              :key="item.id"
              :label="item.periodName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="安检名称" prop="taskName">
          <el-input
            v-model="form.taskName"
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户分类" prop="userType">
          <el-select
            v-model="form.userType"
            placeholder="请选择"
            filterable
            size="small"
            style="width: 100%"
            @change="getTemplate"
          >
            <el-option
              v-for="item in userTypes"
              :key="item.id"
              :label="item.name"
              :value="Number(item.id)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="采集模板" prop="tplId">
          <el-select
            v-if="colList.length > 0"
            v-model="form.tplId"
            placeholder="请选择"
            style="width: 100%"
            filterable
            size="small"
          >
            <el-option
              v-for="item in colList"
              :key="item.id"
              :label="item.tplName"
              :value="parseInt(item.id)"
            >
            </el-option>
          </el-select>
          <template v-else>选择用户分类后自动展示</template>
        </el-form-item>
        <el-form-item label="安检模板" prop="scuTplId">
          <el-select
            v-if="checkList.length > 0"
            v-model="form.scuTplId"
            placeholder="请选择"
            style="width: 100%"
            filterable
            size="small"
          >
            <el-option
              v-for="item in checkList"
              :key="item.id"
              :label="item.tplName"
              :value="parseInt(item.id)"
            >
            </el-option>
          </el-select>
          <template v-else>选择用户分类后自动展示</template>
        </el-form-item>
        <el-form-item
          label="任务时间"
          prop="startTime"
          style="margin-top: 24px"
        >
          <el-date-picker
            v-model="times"
            type="daterange"
            range-separator="至"
            placeholder="请选择时间段"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="setDate"
            size="small"
            style="width: 100%"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="安检员"
          prop="inspectorIds"
          style="margin-top: 24px"
        >
          <el-select
            v-model="form.inspectorIds"
            placeholder="请选择"
            multiple
            filterable
            size="small"
            style="width: 100%"
          >
            <el-option
              v-for="item in checkerList"
              :key="item.id"
              :label="item.realname"
              :value="parseInt(item.id)"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div v-else-if="titleStatus == 3" class="watchLeft">
        <el-descriptions
          class="margin-top"
          :column="1"
          labelClassName="describLable"
        >
          <el-descriptions-item label="安检计划">{{
            form.periodName
          }}</el-descriptions-item>
          <el-descriptions-item label="安检名称">{{
            form.taskName
          }}</el-descriptions-item>
          <el-descriptions-item label="用户分类">{{
            form.userTypeName
          }}</el-descriptions-item>
          <el-descriptions-item label="采集模板">{{
            form.templateName
          }}</el-descriptions-item>
          <el-descriptions-item label="安检模板">{{
            form.scuTemplateName
          }}</el-descriptions-item>
          <el-descriptions-item label="任务时间">{{
            form.startTime + "-" + form.endTime
          }}</el-descriptions-item>
          <el-descriptions-item label="安检员">
            <template v-if="form.inspectorNames">
              <span v-html="changeLine(form.inspectorNames)"></span>
            </template>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="right-part">
      <h3>安检用户</h3>
      <template v-if="titleStatus == 1 || titleStatus == 2">
        <div class="TableTool">
          <div class="TableToolLeft">
            <el-button
              type="text"
              size="mini"
              @click="addUser"
              style="
                border: 1px solid #1082ff;
                color: #1082ff;
                padding: 0 11px;
                height: 30px;
              "
              >添加</el-button
            >
            <el-button
              type="text"
              size="mini"
              @click="delUser(1)"
              style="
                border: 1px solid #f52424;
                color: #f52424;
                padding: 0 11px;
                height: 30px;
              "
              >移除</el-button
            >
            <el-button
              type="text"
              size="mini"
              @click="delUser(2)"
              style="
                border: 1px solid #606266;
                color: #606266;
                padding: 0 11px;
                height: 30px;
              "
              >清空</el-button
            >
          </div>
        </div>
        <div class="tableRight">
          <el-table
            :data="tableData"
            height="calc(100vh - 280px)"
            style="margin-top: 10px"
            border
            :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              background: '#f8f8f9',
              textAlign: 'center',
            }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="150" align="center">
            </el-table-column>
            <el-table-column
              type="index"
              label="序号"
              width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="residentName"
              label="户主姓名"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="mobile"
              label="户主电话"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="addrName" label="片区" align="center">
              <template slot-scope="{ row }">
                {{ row.addrName }}->{{ row.addrsName }}
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="地址"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="sheetSteel"
              label="表编号"
              align="center"
            ></el-table-column>
          </el-table>
          <div class="tableSub">
            <el-button type="primary" @click="subTask" size="small"
              >确定</el-button
            >
          </div>
        </div>
      </template>
      <template v-else-if="titleStatus == 3">
        <rightDetail :houseCount="form.houseCount"></rightDetail>
      </template>
    </div>
    <!-- 添加 -->
    <el-dialog
      :close-on-click-modal="false"
      title="添加常规安检用户"
      :visible.sync="dialogVisible"
      width="70%"
    >
      <div class="addAreaCon">
        <div class="innerCon">
          <div class="addTable">
            <div class="search-part">
              <el-form
                label-position="top"
                label-width="80px"
                inline
                :model="dialogForm"
              >
                <el-form-item label="片区">
                  <el-cascader
                    clearable
                    v-model="addrIds"
                    :options="taskAddrdata"
                    filterable
                    :props="{
                      value: 'trueId',
                      label: 'name',
                      disabled: 'disable',
                      checkStrictly: true,
                    }"
                    size="small"
                    style="width: 200px"
                    @change="diaSearch(1)"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="户主姓名">
                  <el-input
                    v-model="dialogForm.userName"
                    placeholder="请输入关键字"
                    size="small"
                  ></el-input>
                </el-form-item>
                <el-form-item label="户主电话">
                  <el-input
                    v-model="dialogForm.userPhone"
                    placeholder="请输入关键字"
                    size="small"
                  ></el-input>
                </el-form-item>
                <el-form-item label="地址">
                  <el-input
                    v-model="dialogForm.address"
                    placeholder="请输入关键字"
                    size="small"
                  ></el-input>
                </el-form-item>
                <el-form-item label="表编号">
                  <el-input
                    v-model="dialogForm.sheetSteel"
                    placeholder="请输入关键字"
                    size="small"
                  ></el-input>
                </el-form-item>
                <el-form-item label="xx">
                  <el-button
                    type="primary"
                    size="small"
                    style="margin-left: 10px"
                    @click="diaSearch(1)"
                    >查询</el-button
                  >
                  <el-button type="warning" size="small" @click="resetArea"
                    >重置</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
            <el-table
              ref="dialogTableData"
              :data="dialogTableData"
              height="calc(100% - 140px)"
              style="margin-top: 10px"
              border
              :header-cell-style="{
                color: '#333',
                fontFamily: 'MicrosoftYaHeiUI',
                fontSize: '14px',
                fontWeight: 900,
                background: '#f8f8f9',
                textAlign: 'center',
              }"
              @selection-change="dialogChoose"
              @select="selectOne"
              @select-all="selectAll"
              :row-key="getRowKeys"
            >
              <el-table-column
                type="selection"
                width="50"
                reserve-selection
                align="center"
              >
              </el-table-column>
              <el-table-column
                type="index"
                label="序号"
                width="70"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="residentName"
                label="户主姓名"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="mobile"
                label="户主电话"
                width="150"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="addrName" label="片区" align="center">
                <template slot-scope="{ row }">
                  {{ row.addrName }}->{{ row.addrsName }}
                </template>
              </el-table-column>
              <el-table-column
                prop="address"
                label="地址"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="sheetSteel"
                label="表编号"
                align="center"
              ></el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                :current-page="dialogForm.current"
                :background="true"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="dialogForm.size"
                layout="total, prev, pager, next, sizes, jumper"
                :total="areaTotal"
                @size-change="handleDiaSizeChange"
                @current-change="handleDiaCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="choseAll">选择全部</el-button> -->
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="calcArea">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 
<script>
import rightDetail from "./rightDetail";
import { getAddrDropDown } from "@/RequestPort/workorder/maintenRecord";
import {
  dict_selectDictListByPid,
  AaumArchive_inspectorDropDown,
} from "../../../RequestPort/securityTask/unResident";
import {
  arumTaskFindAddrByArumUsers,
  arumTaskFindUsersByTaskIdAndAddrId,
  arumTaskSelectTaskById,
  arumTaskTaskInsertOrUpdate,
  arumAddrFindTree,
  arumTaskTemplatePulldown,
  arumTaskScuTemplatePulldown,
  periodList,
  normalTaskAddrsPageList,
  normalAddrTaskInsertOrUpdate,
  normalAddrTaskPageList,
  normalPartTaskuserPageList,
  normalPartTaskInsertOrUpdate,
  taskDetailUserPageList,
} from "../../../RequestPort/selectQuery/residentRequest.js";
import { selectRepeat } from "../../../RequestPort/dict/cycle";
export default {
  name: "addRetail",
  components: { rightDetail },
  data() {
    return {
      form: {
        periodId: null, // 安检计划id
        taskName: "", //任务名称
        inspectorIds: [], //安检员id数组
        scuTplId: "", //安检模板id
        tplId: "", //采集模板id
        userType: "",
        startTime: "", //任务时间(开始时间)
        endTime: "", //任务时间(结束时间)
        taskCategory: 2, // 任务类别 1常规区域安检 2常规散户安检 3异常安检
        userIds: [],
      },
      userTypes: [],
      checkerList: [],
      rules: {
        periodId: {
          required: true,
          message: "请选择安检计划",
          trigger: "change",
        },
        taskName: {
          required: true,
          message: "请输入安检名称",
          trigger: "blur",
        },
        userType: {
          required: true,
          message: "请选择用户分类",
          trigger: "change",
        },
        inspectorIds: {
          required: true,
          message: "请选择安检员",
          trigger: "change",
        },
        tplId: {
          required: true,
          message: "请先添加采集模板",
          trigger: "change",
        },
        scuTplId: {
          required: true,
          message: "请先添加安检模板",
          trigger: "change",
        },
        startTime: {
          required: true,
          message: "请选择任务时间",
          trigger: "change",
        },
      },
      times: [],
      addrIds: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //若是没有后面的-8.64e7就是不能够选择今天的
        },
      },
      colList: [], // 采集模板下拉
      checkList: [], // 安检模板下拉
      titleStatus: 1,
      periodLists: [], // 安检计划下拉
      tableData: [],

      dialogVisible: false,
      dialogForm: {
        taskId: this.$route.query.id ? this.$route.query.id : "",
        periodId: "",
        addrId: "", // 片区id
        addrsId: "",
        userName: "", // 户主姓名
        userPhone: "", // 电话
        address: "", // 地址
        sheetSteel: "", // 表编号
        size: 100,
        current: 1,
      },
      dialogSearchObj: {},
      areaTotal: 0,
      dialogTableData: [],
      taskAddrdata: [],
      chosedDialogTable: [], // 在弹窗里选中的
      userTypeStart: "",
      chosedTableSub: [],
    };
  },
  mounted() {
    if (this.$route.query.titleStatus) {
      this.titleStatus = this.$route.query.titleStatus;
    }
    console.log(222);
    this.getDesc(this.$route.query.id);
    this.getOption();
    this.gettaskAddrList();
  },
  methods: {
    subTask() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.tableData.length > 0) {
            this.form.userIds = this.tableData.map((item) => {
              return item.id;
            });
          } else {
            if (this.tableData.length === 0) {
              this.$message.error("请添加安检用户");
            }
            return;
          }
          normalPartTaskInsertOrUpdate(this.form).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.data);
              this.close();
            }
          });
        }
      });
    },
    close() {
      delete this.form.id;
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.form.userIds = [];
      this.checkList = [];
      this.colList = [];
      this.tableData = [];
      this.times = [];
      this.$emit("close");
    },
    calcArea() {
      if (!this.chosedDialogTable.length) {
        this.$message.info("请选择安检用户");
        return;
      }
      this.tableData = this.chosedDialogTable;
      this.$refs.dialogTableData.clearSelection()
      this.dialogVisible = false;
    },
    choseAll() {
      // this.dialogForm.size = -1
      // this.dialogForm.current = 1
      // normalPartTaskuserPageList(this.dialogForm).then((res) => {
      //     this.dialogVisible = false
      //     this.tableData = res.data.records
      // });
      this.selectAll(this.dialogTableData);
      this.calcArea();
    },
    resetArea() {
      this.addrIds = [];
      this.dialogForm = {
        taskId: this.$route.query.id,
        periodId: this.form.periodId,
        addrId: "", // 片区id
        userName: "", // 户主姓名
        userPhone: "", // 电话
        address: "", // 地址
        sheetSteel: "", // 表编号
        userType: this.form.userType,
        size: 100,
        current: 1,
      };
      this.copyObj(1);
      this.getDialogList();
    },
    dialogChoose(e) {
      // console.log(e)
      // this.chosedDialogTable = e
    },
    selectOne(selection, row) {
      console.log(row, 555);
      let flag = false;
      this.chosedDialogTable.forEach((item, index) => {
        if (item.id == row.id) {
          flag = true;
          this.chosedDialogTable.splice(index, 1);
          this.$refs.dialogTableData.toggleRowSelection(item, false);
        }
      });
      !flag && this.chosedDialogTable.unshift(row);
    },
    selectAll(e) {
      // 去重
      let obj = {};
      let pes = e.reduce((cur, next) => {
        obj[next.id] ? "" : (obj[next.id] = true && cur.push(next));
        return cur;
      }, []);
      // 取列表和选中的相同的
      let identical = this.dialogTableData.filter(function (val) {
        return e.indexOf(val) > -1;
      });
      // 取消全选
      if (this.dialogTableData.length != identical.length) {
        this.chosedDialogTable = this.chosedDialogTable.filter(
          (item) => !this.dialogTableData.some((ele) => ele.id === item.id)
        );
      } else {
        let l = this.dialogForm.size;
        let allL = e.length;
        // let a = e.slice(allL - l, allL);
        let a = allL > l ? e.slice(allL - l, allL) : e;

        let list = [...a, ...this.chosedDialogTable];
          console.log(list)
        let obj1 = {};
        let pes1 = list.reduce((cur1, next1) => {
          obj1[next1.id] ? "" : (obj1[next1.id] = true && cur1.push(next1));
          return cur1;
        }, []);
        // 全选
        this.chosedDialogTable = pes1;
      }
    },
    handleDiaSizeChange(val) {
      this.copyObj(2);
      this.dialogForm.current = 1;
      this.dialogForm.size = val;
      this.dialogSearchObj.current = 1;
      this.dialogSearchObj.size = val;
      this.getDialogList();
    },
    handleDiaCurrentChange(val) {
      this.dialogSearchObj.current = val;
      this.copyObj(2);
      // this.dialogForm.current = val
      this.getDialogList();
    },
    addUser() {
      if (!this.form.userType) {
        this.$message.error("请先选择用户分类");
        return;
      }
      this.resetArea()
      // this.dialogForm.size = 30;
      // this.dialogForm.current = 1;
      // this.dialogForm.periodId = this.form.periodId;
      // this.dialogForm.userType = this.form.userType;
      
      this.chosedDialogTable = this.tableData.map((item) => {
        return item;
      });
      // this.getDialogList(1);
      // this.resetArea()
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.dialogTableData &&
          this.$refs.dialogTableData.clearSelection();
      });
    },
    getDialogList(val) {
      if(this.$refs.dialogTableData){
        this.$refs.dialogTableData.clearSelection()
      }
      val ? (this.dialogSearchObj.current = val) : "";
      if (this.addrIds.length == 1) {
        this.dialogSearchObj.addrId = this.addrIds[0];
        this.dialogSearchObj.addrsId = "";
        this.dialogSearchObj.addrssId = "";
      } else if (this.addrIds.length == 2) {
        this.dialogSearchObj.addrsId = this.addrIds[1];
        this.dialogSearchObj.addrssId = "";
        this.dialogSearchObj.addrId = "";
      }else if (this.addrIds.length == 3) {
        this.dialogSearchObj.addrsId = '';
        this.dialogSearchObj.addrssId = this.addrIds[2];
        this.dialogSearchObj.addrId = "";
      } else {
        this.dialogSearchObj.addrssId = '';
        this.dialogSearchObj.addrsId = "";
        this.dialogSearchObj.addrId = "";
      }
      if (this.userTypeStart) {
        this.dialogSearchObj.userType = this.userTypeStart;
      }
      normalPartTaskuserPageList(this.dialogSearchObj).then((res) => {
        this.dialogTableData = res.data.records;
        this.dialogTableData.forEach((item) => {
          // this.tableData.forEach(i=>{
          this.chosedDialogTable.forEach((i) => {
            if (i.id === item.id) {
              // this.chosedDialogTable.push(item)
              this.$refs.dialogTableData.toggleRowSelection(item, true);
            }
          });
        });
        this.areaTotal = res.data.total;
      });
    },
    delUser(val) {
      if (val == 1) {
        if (this.chosedTableSub.length) {
          this.$confirm("是否移除?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            let arr = this.chosedTableSub.map((item) => {
              return item.id;
            });
            this.tableData = this.tableData.filter((item) => {
              return !arr.includes(item.id);
            });
          });
        } else {
          this.$message.error("请先选择数据");
        }
      } else {
        this.$confirm("是否清空全部数据", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          type: "warning",
        })
          .then(() => {
            this.tableData = [];
          })
          .catch(() => {});
      }
    },
    handleSelectionChange(e) {
      console.log(e);
      this.chosedTableSub = e;
    },
    getRowKeys(row) {
      return row.id;
    },
    getOption() {
      dict_selectDictListByPid({ pid: 732 }).then((res) => {
        this.userTypes = res.data;
      });

      AaumArchive_inspectorDropDown({}).then((res) => {
        this.checkerList = res.data;
      });
    },
    getDesc(id) {
      this.$nextTick(() => {
        if (this.titleStatus == 1) {
          this.getPeriod().then((data) => {
            // if (this.$refs.ruleForm) {
            //   this.$refs.ruleForm.resetFields();
            // }
            !this.form.periodId ? (this.form.periodId = data[0].id) : "";
          });
        } else {
          arumTaskSelectTaskById({ taskId: id }).then((res) => {
            this.getPeriod();
            arumTaskTemplatePulldown({ userType: res.data.userType }).then(
              (innerRes) => {
                // 采集模板
                this.colList = innerRes.data;
              }
            );
            arumTaskScuTemplatePulldown({ userType: res.data.userType }).then(
              (innerRes) => {
                // 安检模板
                this.checkList = innerRes.data;
              }
            );
            this.form = res.data;
            this.form.periodId = this.form.periodId.toString();
            this.userTypeStart = this.form.userType;
            this.times = [
              res.data.startTime.replace(/\//g, "-"),
              res.data.endTime.replace(/\//g, "-"),
            ];
          });
          taskDetailUserPageList({ taskId: id, size: -1 }).then((res) => {
            this.tableData = res.data.records;
            // this.form.userIds = res.data.addrsIds;
          });
        }
      });
    },
    // 获取是否可重复
    async getRepeated() {
      let data = selectRepeat().then((res) => {
        return res.data === 1 ? true : false;
      });
      return Promise.resolve(data);
    },
    async getPeriod() {
      // 安检计划下拉
      let data = periodList().then((res) => {
        this.periodLists = res.data;

        return res.data;
      });
      return Promise.resolve(data);
    },
    setDate(val) {
      this.form.startTime = this.times[0];
      this.form.endTime = this.times[1];
    },
    getTemplate(val) {
      this.userTypeStart = val;
      arumTaskTemplatePulldown({ userType: val }).then((res) => {
        this.form.tplId = "";
        if (res.data) {
          this.colList = res.data;
        } else {
          this.colList = [];
        }
        // this.$refs.ruleForm.validateField("templateName");
      });
      arumTaskScuTemplatePulldown({ userType: val }).then((res) => {
        this.form.scuTplId = "";
        if (res.data) {
          this.checkList = res.data;
        } else {
          this.checkList = [];
        }
        // this.$refs.ruleForm.validateField(["scuTplId", "tplId"]);
      });
      this.tableData = [];
    },
    cleardata() {
      selectRepeat().then((res) => {
        if (res.data === 2) {
          this.tableData = [];
        }
      });
    },
    /**
     * 片区下拉
     */
    gettaskAddrList() {
      getAddrDropDown().then((res) => {
        res.data.forEach((item) => {
          if (item.children) {
            item.disable = false;
          } else {
            item.disable = true;
          }
          this.taskAddrdata = res.data;
        });
      });
    },
    changeLine(e) {
      return e.replace(/,/g, "<br />");
    },
    diaSearch() {
      this.dialogForm.current = 1;
      this.copyObj(1);
      this.getDialogList();
    },
    copyObj(val) {
      if (val == 1) {
        // 正向拷贝
        for (let key in this.dialogForm) {
          this.dialogSearchObj[key] = this.dialogForm[key];
        }
      } else if (val == 2) {
        // 反向拷贝
        for (let key in this.dialogSearchObj) {
          this.dialogForm[key] = this.dialogSearchObj[key]
          
        }
      }
    },
  },
};
</script>
 
<style scoped lang="less">
.contain {
  height: calc(100% - 114px);
  background-color: #e9eef3;
  display: flex;
  h3 {
    height: 42px;
    line-height: 42px;
    padding: 0 20px;
    border-bottom: 1px solid #ebebeb;
  }
  .left-part {
    background-color: #fff;
    width: 280px;
    height: 100%;
    margin-right: 10px;
    /deep/.el-form {
      padding: 0 20px;
      .el-form-item {
        margin-bottom: 10px;
      }
      .el-form-item__label {
        height: 32px;
      }
    }
    .watchLeft {
      padding: 36px 20px;
      /deep/.describLable {
        color: #909399;
      }
      /deep/.el-descriptions-item {
        padding-bottom: 31px;
      }
    }
  }
  .right-part {
    background-color: #fff;
    width: calc(100% - 290px);
    height: 100%;
    .TableTool {
      padding: 10px 20px;
    }
    .tableRight {
      padding: 0px 20px;
      .tableSub {
        float: right;
        margin-top: 10px;
      }
    }
  }
}
.addAreaCon {
  // padding: 30px;
  height: 560px;
  .innerCon {
    border: 1px solid #dcdfe6;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    .addTable {
      padding: 10px;
      width: 100%;
      .search-part {
        display: flex;
        .el-form-item {
          margin-bottom: 10px;
          /deep/.el-form-item__label {
            height: 32px;
          }
        }
        .el-form-item:last-child {
          /deep/.el-form-item__label {
            visibility: hidden;
          }
        }
      }
    }
  }
}
/deep/.el-dialog__header {
  background-color: #f9f9f9;
  padding: 20px;
  font-weight: bold;
  .el-dialog__title {
    font-size: 16px;
  }
  .el-dialog__title:before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 16px;
    background: #1082ff;
    margin-right: 10px;
    vertical-align: middle;
  }
}
</style>