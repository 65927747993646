
import axios from '@/common/js/request'

export function dict_selectDictListByPid(data){
    const dict_selectDictListByPid = axios({
        url: '/dict/selectDictListByPid',
        method: 'get',
        params:data
    })
    return dict_selectDictListByPid
}
export function aaumTask_showTempletByUserType(data){
    const aaumTask_showTempletByUserType = axios({
        url: '/aaumTask/showTempletByUserType',
        method: 'get',
        params:data
    })
    return aaumTask_showTempletByUserType
}
export function aaumTask_findUserTreeEcho(data){
    const aaumTask_findUserTreeEcho = axios({
        url: '/aaumTask/findUserTreeEcho',
        method: 'get',
        params:data
    })
    return aaumTask_findUserTreeEcho
}
export function aaumTask_findAddrByAaumUsers(data){
    const aaumTask_findAddrByAaumUsers = axios({
        url: '/aaumTask/findAddrByAaumUsers',
        method: 'post',
        data:data
    })
    return aaumTask_findAddrByAaumUsers
}
export function AaumArchive_inspectorDropDown(data){
    const AaumArchive_inspectorDropDown = axios({
        url: '/AaumArchive/inspectorDropDown',
        method: 'get',
        params:data
    })
    return AaumArchive_inspectorDropDown
}
export function aaumTask_taskInsertOrUpdate(data){
    const aaumTask_taskInsertOrUpdate = axios({
        url: '/aaumTask/taskInsertOrUpdate',
        method: 'post',
        data:data
    })
    return aaumTask_taskInsertOrUpdate
}
export function aaumTask_selectTaskById(data){
    const aaumTask_selectTaskById = axios({
        url: '/aaumTask/selectTaskById',
        method: 'get',
        params:data
    })
    return aaumTask_selectTaskById
}
export function aaumTask_findUsersByTaskIdAndAddrId(data){
    const aaumTask_findUsersByTaskIdAndAddrId = axios({
        url: '/aaumTask/findUsersByTaskIdAndAddrId',
        method: 'get',
        params:data
    })
    return aaumTask_findUsersByTaskIdAndAddrId
}
export function aaumTask_taskUpdate(data){
    const aaumTask_taskUpdate = axios({
        url: '/aaumTask/taskUpdate',
        method: 'post',
        data:data
    })
    return aaumTask_taskUpdate
}
export function aaumTask_exportTaskExcel(params){
    const that = this;
    const aaumTask_exportTaskExcel = axios({
        url: '/aaumTask/exportTaskExcel',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob', //改变接收的值类型
        isloading:true
    })
    return aaumTask_exportTaskExcel
}